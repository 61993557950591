<template>
  <v-container class="mt-1">
    <v-row justify="space-between">
      <v-col cols="12" md="4" v-for="(item, i) in stockMenu" :key="i">
        <v-card elevation="1" @click="goTo(item.route)">
          <v-card-title
            class="body-1 orange darken-3 text-capitalize title infoCard"
          >
            <v-icon small left dark>
              {{ item.icon }}
            </v-icon>
            <span class="white--text">{{ $t(item.title) }}</span>
          </v-card-title>
          <v-card-text class="pt-5">
            {{ $t(item.description) }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    stockMenu: [
      {
        icon: "mdi-shopping",
        title: "new product",
        description: "enter stock details",
        route: "StockItems",
      },
      {
        icon: "mdi-store",
        title: "stock management",
        description: "update stock",
        route: "ViewStock",
      },
      {
        icon: "mdi-file-document",
        title: "purchase orders",
        description: "create and manage purchase orders",
        route: "ViewPurchaseOrders",
      },
    ],
  }),

  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
  },
};
</script>

<style scoped>
.title {
  height: 70px;
}
</style>
